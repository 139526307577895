import MainDocumentEditor from "../components/MainDocumentEditor";
import DocumentEditorHeader from "../components/DocumentEditorHeader";
import NoteEditor from "../components/NoteEditor";
import { useCurrentDraft } from "../hooks/useCurrentDraft";
import { useEffect, useMemo, useState, useRef } from "react";
import {
  resetDraftState,
  setDraftSplitFraction,
  setScratchpadSplitFraction,
} from "../redux/draftStore";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Button, Modal, ModalContent, ModalHeader } from "@nextui-org/react";
import { useCreateActivity } from "../hooks/useCreateActivity";
import { useCurrentActivity } from "../hooks/useCurrentActivity";
import { Panel, PanelGroup } from "react-resizable-panels";
import ResizeHandle from "../components/ResizeHandle";
import { gql, useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { RoomPresets } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import UnauthenticatedDocumentEditorHeader from "../components/UnauthenticatedDocumentEditorHeader";
import { useMediaQuery } from "react-responsive";
import DocumentFooter from "../components/DocumentFooter";
import DesktopRoomPanel from "../components/DesktopRoomPanel";
import MobileRoomPanel from "../components/MobileRoomPanel";

const UPDATE_NOTE_DRAFT_SPLIT = gql`
  mutation updateNoteDraftSplit(
    $scratchpadSplitFraction: Float!
    $draftSplitFraction: Float!
    $draftID: String!
  ) {
    updateDraftSplitFraction(
      request: {
        scratchpadSplitFraction: $scratchpadSplitFraction
        draftSplitFraction: $draftSplitFraction
        draftID: $draftID
      }
    ) {
      success
    }
  }
`;

function EditorPage() {
  const dispatch = useDispatch();
  const [updateNoteDraftSplit] = useMutation(UPDATE_NOTE_DRAFT_SPLIT);
  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  const { roomID, draftID } = useParams();
  const { isLoggedIn, settled } = useUserInfo();
  const navigate = useNavigate();

  const isPresetRoom = useMemo(() => {
    return roomID === RoomPresets.TheEveryoneRoom;
  }, [roomID]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [liveWordCount, setLiveWordCount] = useState<number>(0);
  const [mobileRoomPanelHeight, setMobileRoomPanelHeight] = useState<number>(0);

  console.log("mobileRoomPanelHeight", mobileRoomPanelHeight);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(
        `${process.env.REACT_APP_API_ROOT}:${process.env.REACT_APP_EXTERNAL_WS_PORT}`,
        {
          transports: ["websocket"],
        }
      );
      setSocket(newSocket);
    }
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [socket, setSocket]);

  useCurrentDraft(); // load draft data into redux store
  const outOfSync = useSelector((state: RootState) => state.draft.outOfSync);
  const scratchpadSplitFraction = useSelector(
    (state: RootState) => state.draft.scratchpadSplitFraction
  );
  const draftSplitFraction = useSelector(
    (state: RootState) => state.draft.draftSplitFraction
  );

  const debouncedUpdateNoteDraftSplit = useDebouncedCallback(async () => {
    await updateNoteDraftSplit({
      variables: {
        scratchpadSplitFraction: scratchpadSplitFraction,
        draftSplitFraction: draftSplitFraction,
        draftID: draftID,
      },
    });
  }, 300);

  useEffect(() => {
    if (isLoggedIn) {
      debouncedUpdateNoteDraftSplit();
    }
  }, [
    scratchpadSplitFraction,
    draftSplitFraction,
    debouncedUpdateNoteDraftSplit,
    isLoggedIn,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetDraftState());
      resetDraftState();
    };
  }, [dispatch]);
  const currentActivityData = useCurrentActivity();
  const { createActivityIfNoCurrent } = useCreateActivity();

  useEffect(() => {
    if (!roomID) {
      navigate("/");
    }
  }, [roomID, navigate]);

  useEffect(() => {
    if (!isLoggedIn && draftID !== "new" && settled) {
      navigate(`/room/${roomID}/draft/new`);
    }
  }, [isLoggedIn, draftID, navigate, roomID, settled]);

  return scratchpadSplitFraction !== null && draftSplitFraction !== null ? (
    <div className="h-full flex-col flex overflow-hidden">
      {isLoggedIn ? (
        <DocumentEditorHeader socket={socket} />
      ) : (
        <UnauthenticatedDocumentEditorHeader socket={socket} />
      )}
      <Modal isOpen={outOfSync} isDismissable={false}>
        <ModalHeader>Draft out of sync</ModalHeader>
        <ModalContent>
          <div className="font-sans flex-col" style={{ padding: 20 }}>
            <p>
              Your draft is out of sync with the server. Reload the page to
              resync.
            </p>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <PanelGroup direction="horizontal">
        {!isMobile && [
          <Panel
            defaultSize={scratchpadSplitFraction * 100}
            className="h-full flex flex-col"
            onResize={(updatedPercentage) => {
              if (isLoggedIn) {
                dispatch(setScratchpadSplitFraction(updatedPercentage / 100));
              }
            }}
            minSize={10}
          >
            <NoteEditor />
          </Panel>,
          <ResizeHandle />,
          <Panel
            minSize={40}
            defaultSize={draftSplitFraction * 100}
            onResize={(updatedPercentage) => {
              if (isLoggedIn) {
                dispatch(setDraftSplitFraction(updatedPercentage / 100));
              }
            }}
          >
            <div className="h-full flex flex-col">
              <MainDocumentEditor
                socket={socket}
                setLiveWordCount={setLiveWordCount}
              />
            </div>
          </Panel>,
          <ResizeHandle />,
          <Panel minSize={20}>
            <div className="h-full flex flex-col">
              <DesktopRoomPanel socket={socket} />
            </div>
          </Panel>,
        ]}
        {isMobile && (
          <div className="h-full w-full flex flex-col">
            <MainDocumentEditor
              socket={socket}
              setLiveWordCount={setLiveWordCount}
              maxHeight={mobileRoomPanelHeight}
            />
            <MobileRoomPanel
              socket={socket}
              onHeightChange={setMobileRoomPanelHeight}
            />
          </div>
        )}
      </PanelGroup>
      <DocumentFooter liveWordCount={liveWordCount} />
    </div>
  ) : null;
}

export default EditorPage;
