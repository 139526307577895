import { Button, Image } from "@nextui-org/react";
import Logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";

import ProfileMenu from "./ProfileMenu";
import { useUserInfo } from "../hooks/useUserInfo";
import { useMediaQuery } from "react-responsive";

function Header({ children }: { children?: React.ReactNode }) {
  const { isLoggedIn } = useUserInfo();

  const isSmallMobile = useMediaQuery({
    query: "(max-width: 650px)",
  });
  const location = useLocation();
  const isHome = useMemo(() => location.pathname === "/", [location.pathname]);
  return (
    <>
      <div
        className="w-full flex flex-row py-2 items-center border-b border-solid border-black px-3 fixed"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        {isHome ? (
          <Image width={40} style={{ minWidth: 40 }} src={Logo} />
        ) : (
          <Link to="/">
            <Image width={40} style={{ minWidth: 40 }} src={Logo} />
          </Link>
        )}
        <div className="flex-1">{children}</div>
        {isLoggedIn ? (
          <ProfileMenu />
        ) : (
          <div className="flex flex-row">
            <div style={{ marginRight: 8 }}>
              <a href="/login">
                <Button
                  variant="bordered"
                  color="primary"
                  className="font-sans"
                >
                  Login
                </Button>
              </a>
            </div>
            {!isSmallMobile && (
              <a href="/signup">
                <Button color="primary" className="font-sans">
                  Create account
                </Button>
              </a>
            )}
          </div>
        )}
      </div>
      <div style={{ minHeight: 57 }}></div>
    </>
  );
}

export default Header;
