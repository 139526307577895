import { AddSquare, CalendarAdd, Lock, Timer1, User } from "iconsax-react";
import EarthBackground from "../assets/earth-background.png";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoomMetadata, UserMetadata, UserRoomStatus } from "../gql/graphql";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { formatTimeDifference, getGoogleCalendarLink } from "../utils/string";
import { Button, Modal, ModalContent } from "@nextui-org/react";
import { motion } from "framer-motion";
import ProfileAvatar from "./ProfileAvatar";
import { useUserInfo } from "../hooks/useUserInfo";

const GET_ROOM_LIST = gql`
  query GetRoomListRoomsGrid {
    getRoomList {
      sharedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
        friendsInRoom {
          username
          userID
          profilePhotoURL
          profilePlaceholder
        }
        roomStatus
        scheduledStartTime
        joinedUsers {
          username
          userID
          profilePhotoURL
          profilePlaceholder
        }
      }
      ownedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
        friendsInRoom {
          username
          userID
          profilePhotoURL
          profilePlaceholder
        }
        roomStatus
        scheduledStartTime
      }
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraft {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

const DECLINE_ROOM_INVITE = gql`
  mutation DeclineRoomInvite($roomID: String!) {
    declineRoomInvite(request: { roomID: $roomID }) {
      success
    }
  }
`;

const JOIN_ROOM = gql`
  mutation JoinRoom($roomID: String!) {
    joinRoom(request: { roomID: $roomID }) {
      success
    }
  }
`;

const LEAVE_ROOM = gql`
  mutation LeaveRoom($roomID: String!) {
    leaveRoom(request: { roomID: $roomID }) {
      success
    }
  }
`;

const CLICK_CALENDAR_BUTTON = gql`
  query ClickCalendarButton($roomID: String!) {
    clickedCalendarButton(request: { roomID: $roomID }) {
      success
    }
  }
`;

function roomsAreSame(room1: RoomMetadata, room2: RoomMetadata) {
  return (
    room1.roomID === room2.roomID &&
    room1.name === room2.name &&
    room1.private === room2.private &&
    room1.permanent === room2.permanent &&
    room1.ownerID === room2.ownerID &&
    room1.numUsersInRoom === room2.numUsersInRoom &&
    JSON.stringify(room1.friendsInRoom) ===
      JSON.stringify(room2.friendsInRoom) &&
    room1.imageDownloadURL?.split("?")[0] ===
      room2.imageDownloadURL?.split("?")[0]
  );
}

const CachedBackgroundImageDiv = React.memo(
  ({
    roomID,
    draftID,
    imageURL,
    children,
    topChild,
    clickable,
  }: {
    imageURL: string;
    roomID: string;
    draftID?: string;
    topChild?: React.ReactNode;
    children: React.ReactNode;
    clickable: boolean;
  }) => {
    const navigate = useNavigate();
    return (
      <div
        className={`flex flex-col items-center ${
          clickable ? "cursor-pointer" : ""
        } ${topChild ? "justify-between" : "justify-end"}`}
        style={{
          backgroundImage: `url(${imageURL})`,
          backgroundSize: "cover",
          width: 200,
          height: 200,
          minWidth: 200,
          minHeight: 200,
          borderRadius: 6,
        }}
        onClick={() => {
          if (clickable) {
            navigate(`/room/${roomID}${draftID ? `/draft/${draftID}` : ""}`);
          }
        }}
      >
        {topChild}
        {children}
      </div>
    );
  }
);

function getUpdatedRoomList(
  newRooms: RoomMetadata[],
  oldRooms: RoomMetadata[]
) {
  let sharedRoomsChanged = false;

  let finalRooms = [];

  for (let i = 0; i < newRooms.length; i++) {
    const matchingRoom = oldRooms.find(
      (room) => room.roomID === newRooms[i].roomID
    );
    if (!matchingRoom) {
      sharedRoomsChanged = true;
    } else if (!roomsAreSame(newRooms[i], matchingRoom)) {
      sharedRoomsChanged = true;
    }
    const urlsAreEqual =
      newRooms[i].imageDownloadURL?.split("?")[0] ===
      matchingRoom?.imageDownloadURL?.split("?")[0];
    finalRooms.push({
      ...newRooms[i],
      imageDownloadURL: urlsAreEqual
        ? matchingRoom?.imageDownloadURL
        : newRooms[i].imageDownloadURL,
    });
  }

  // Handle removal of rooms that are no longer present in the fetched room list
  const removedRooms = oldRooms.filter(
    (oldRoom) => !newRooms.some((newRoom) => newRoom.roomID === oldRoom.roomID)
  );
  if (removedRooms.length > 0) {
    sharedRoomsChanged = true;
    finalRooms = finalRooms.filter(
      (room) =>
        !removedRooms.some((removedRoom) => removedRoom.roomID === room.roomID)
    );
  }

  return { finalRooms, sharedRoomsChanged };
}

export default function RoomsGrid() {
  const [createNewHovered, setCreateNewHovered] = useState(false);
  const {
    isLoggedIn,
    settled,
    username,
    profilePlaceholder,
    profilePhotoDownloadURL,
    userID,
  } = useUserInfo();

  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [joinRoom] = useMutation(JOIN_ROOM);
  const [leaveRoom] = useMutation(LEAVE_ROOM);

  const [declineRoomInvite] = useMutation(DECLINE_ROOM_INVITE);
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());

  const [hoveredRoomID, setHoveredRoomID] = useState<string | null>(null);
  const [hoveredFriendID, setHoveredFriendID] = useState<string | null>(null);

  const [invitedRooms, setInvitedRooms] = useState<RoomMetadata[]>([]);
  const [scheduledRooms, setScheduledRooms] = useState<RoomMetadata[]>([]);
  const [sharedRooms, setSharedRooms] = useState<RoomMetadata[]>([]);
  const [ownedRooms, setOwnedRooms] = useState<RoomMetadata[]>([]);
  const [clickedCalendarButton] = useLazyQuery(CLICK_CALENDAR_BUTTON);
  const [userMetadataListToShow, setUserMetadataListToShow] = useState<
    UserMetadata[] | null
  >(null);
  const [hoveringFriendUsername, setHoveringFriendUsername] = useState<
    string | null
  >(null);

  const lastRefetchTime = useRef(0);

  const updateRoomsFromRefetch = useCallback(
    (data: any) => {
      if (data?.getRoomList) {
        const { sharedRooms: sharedRoomsData, ownedRooms: ownedRoomsData } =
          data.getRoomList;

        // Filter out invited rooms from shared rooms
        const filteredSharedRooms = sharedRoomsData.filter(
          (room: RoomMetadata) => room.roomStatus !== UserRoomStatus.Invited
        );

        const sharedOngoingRooms = filteredSharedRooms.filter(
          (room: RoomMetadata) => room.scheduledStartTime === null
        );

        const scheduledRoomsData = filteredSharedRooms.filter(
          (room: RoomMetadata) => room.scheduledStartTime !== null
        );

        // shared rooms updating
        const { finalRooms, sharedRoomsChanged } = getUpdatedRoomList(
          sharedOngoingRooms,
          sharedRooms
        );
        if (sharedRoomsChanged) {
          setSharedRooms(finalRooms);
        }

        // owned rooms updating
        const {
          finalRooms: finalOwnedRooms,
          sharedRoomsChanged: ownedRoomsChanged,
        } = getUpdatedRoomList(ownedRoomsData, ownedRooms);
        if (ownedRoomsChanged) {
          setOwnedRooms(finalOwnedRooms);
        }

        // invited rooms updating
        const invitedRoomsData = sharedRoomsData.filter(
          (room: RoomMetadata) => room.roomStatus === UserRoomStatus.Invited
        );
        const {
          finalRooms: finalInvitedRooms,
          sharedRoomsChanged: invitedRoomsChanged,
        } = getUpdatedRoomList(invitedRoomsData, invitedRooms);
        if (invitedRoomsChanged) {
          setInvitedRooms(finalInvitedRooms);
        }

        // scheduled rooms updating
        const {
          finalRooms: finalScheduledRooms,
          sharedRoomsChanged: scheduledRoomsChanged,
        } = getUpdatedRoomList(scheduledRoomsData, scheduledRooms);

        if (scheduledRoomsChanged) {
          setScheduledRooms(finalScheduledRooms);
        }
      }
    },
    [invitedRooms, ownedRooms, scheduledRooms, sharedRooms]
  );

  const { data, loading, refetch } = useQuery(GET_ROOM_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      updateRoomsFromRefetch(data);
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // only refetch every 5 seconds
      if (Date.now() - lastRefetchTime.current > 5000) {
        refetch().then((data) => {
          updateRoomsFromRefetch(data.data);
        });
        lastRefetchTime.current = Date.now();
      }
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [refetch, updateRoomsFromRefetch]);

  // call it once on load
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleAccept = useCallback(
    (roomID: string) => {
      navigate(`/room/${roomID}`);
    },
    [navigate]
  );

  const handleDecline = useCallback(
    async (roomID: string) => {
      const filteredRooms = invitedRooms.filter(
        (room) => room.roomID !== roomID
      );
      setInvitedRooms(filteredRooms);
      await declineRoomInvite({ variables: { roomID } });
    },
    [declineRoomInvite, invitedRooms]
  );

  const joinRoomStateUpdate = useCallback(
    async (roomID: string) => {
      await joinRoom({ variables: { roomID } });
      const newScheduledRooms: RoomMetadata[] = [];
      for (const scheduledRoom of scheduledRooms) {
        if (scheduledRoom.roomID !== roomID) {
          newScheduledRooms.push(scheduledRoom);
        } else if (username && userID) {
          newScheduledRooms.push({
            ...scheduledRoom,
            roomStatus: UserRoomStatus.Joined,
            joinedUsers: [
              ...scheduledRoom.joinedUsers,
              {
                username,
                profilePlaceholder,
                userID,
                profilePhotoURL: profilePhotoDownloadURL,
              },
            ],
          });
        }
      }
      setScheduledRooms(newScheduledRooms);
    },
    [
      joinRoom,
      profilePhotoDownloadURL,
      profilePlaceholder,
      scheduledRooms,
      userID,
      username,
    ]
  );

  const leaveRoomStateUpdate = useCallback(
    async (roomID: string) => {
      await leaveRoom({ variables: { roomID } });
      const newScheduledRooms: RoomMetadata[] = [];
      for (const scheduledRoom of scheduledRooms) {
        if (scheduledRoom.roomID !== roomID) {
          newScheduledRooms.push(scheduledRoom);
        } else {
          newScheduledRooms.push({
            ...scheduledRoom,
            joinedUsers: scheduledRoom.joinedUsers.filter(
              (user) => user.userID !== userID
            ),
            roomStatus: UserRoomStatus.None,
          });
        }
      }
      setScheduledRooms(newScheduledRooms);
    },
    [leaveRoom, scheduledRooms, userID]
  );

  return (
    <div
      className="w-full"
      style={{
        marginTop: 20,
        paddingBottom: 20,
        marginLeft: 64,
        height: "calc(100dvh - 77px)",
        maxHeight: "calc(100dvh - 77px)",
        overflowY: "auto",
      }}
    >
      {invitedRooms.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "white",
            borderRadius: 10,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: 16,
            minWidth: 300,
            maxHeight: 400,
            overflowY: "auto",
          }}
        >
          <h3
            className="font-sans text-lg font-bold mb-4"
            style={{ marginBottom: 20 }}
          >
            Room Invitations
          </h3>
          {invitedRooms.map((room) => (
            <div
              key={room.roomID}
              className="mb-4 pb-4"
              style={{ marginBottom: 10 }}
            >
              <div className="flex justify-between mb-2">
                <div className="flex items-center" style={{ marginRight: 30 }}>
                  <img
                    key={`${room.roomID}-invite-img-${room.imageDownloadURL}`}
                    src={room.imageDownloadURL || EarthBackground}
                    alt={room.name}
                    className="w-10 h-10 mr-3"
                    style={{
                      borderRadius: 6,
                      marginRight: 10,
                    }}
                  />
                  <p className="font-sans font-semibold">{room.name}</p>
                </div>
                <div className="flex justify-end items-center">
                  <Button
                    size="sm"
                    color="success"
                    className="font-sans mr-2"
                    style={{
                      color: "white",
                    }}
                    onClick={() => handleAccept(room.roomID)}
                  >
                    Accept
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    className="font-sans"
                    onClick={() => handleDecline(room.roomID)}
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* scheduled rooms section */}
      {scheduledRooms.length > 0 && (
        <div className="flex flex-col items-start">
          <div>
            <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
              Upcoming rooms
            </p>
          </div>
          <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
            {loading ? (
              <div style={{ height: 200 }} />
            ) : (
              scheduledRooms.map((room: RoomMetadata) => (
                <div
                  className="flex flex-col"
                  style={{
                    backgroundColor:
                      room.roomStatus === UserRoomStatus.Joined
                        ? "#45D483"
                        : "rgb(210, 210, 210)",
                    borderRadius: 6,
                  }}
                >
                  <CachedBackgroundImageDiv
                    clickable={false}
                    imageURL={room.imageDownloadURL || EarthBackground}
                    key={room.roomID}
                    roomID={room.roomID}
                    draftID={
                      mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                        .draftID
                    }
                    topChild={
                      <div
                        className="w-full blurred-div-light flex justify-center"
                        style={{
                          padding: 10,
                          borderTopLeftRadius: 6,
                          borderTopRightRadius: 6,
                        }}
                      >
                        <p
                          className="font-sans text-black"
                          style={{ fontWeight: "bold" }}
                        >
                          {new Date(room.scheduledStartTime).toLocaleString(
                            undefined,
                            {
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                      </div>
                    }
                  >
                    <div
                      className="flex flex-col blurred-div"
                      style={{
                        margin: 10,
                        borderRadius: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        width: 180,
                      }}
                    >
                      <p
                        className="font-sans text-white"
                        style={{
                          fontWeight: "bold",
                          fontSize: 16,
                          marginBottom: 4,
                        }}
                      >
                        {room.name}
                      </p>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center">
                          {room.private && (
                            <div style={{ marginRight: 5 }}>
                              <Lock color="white" variant="Bold" size={15} />
                            </div>
                          )}
                          {room.endTime &&
                          new Date(room.endTime) > currentTime ? (
                            <div className="flex flex-row items-center">
                              <div style={{ marginRight: 5 }}>
                                <Timer1 color="white" size={15} />
                              </div>
                              <p
                                className="font-sans"
                                style={{ fontSize: 12, color: "white" }}
                              >
                                {formatTimeDifference(
                                  new Date(room.endTime),
                                  currentTime
                                )}
                              </p>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                        <div className="flex flex-row items-center">
                          <div
                            className="flex flex-row items-center"
                            style={{ marginRight: 6 }}
                            onMouseEnter={() => setHoveredRoomID(room.roomID)}
                            onMouseLeave={() => setHoveredRoomID(null)}
                          >
                            {room.friendsInRoom.map((friend) => (
                              <motion.div
                                animate={{
                                  marginLeft:
                                    hoveredRoomID === room.roomID ? 2 : -8,
                                }}
                                transition={{
                                  duration: 0.1,
                                }}
                                style={{
                                  position: "relative",
                                }}
                                key={friend.userID}
                              >
                                <ProfileAvatar
                                  src={friend.profilePhotoURL || ""}
                                  profilePlaceholder={friend.profilePlaceholder}
                                  style={{
                                    width: 18,
                                    height: 18,
                                  }}
                                  onMouseEnter={() =>
                                    setHoveredFriendID(friend.userID)
                                  }
                                  onMouseLeave={() => setHoveredFriendID(null)}
                                />
                                {hoveredRoomID === room.roomID &&
                                  hoveredFriendID === friend.userID && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: -40,
                                        left: -10,
                                        backgroundColor: "black",
                                        padding: 5,
                                        margin: 5,
                                      }}
                                    >
                                      <p
                                        className="font-sans"
                                        style={{ color: "white", fontSize: 12 }}
                                      >
                                        {friend.username}
                                      </p>
                                    </div>
                                  )}
                              </motion.div>
                            ))}
                          </div>
                          <div
                            className="flex flex-row items-center cursor-pointer"
                            onClick={() => {
                              if (userMetadataListToShow) {
                                setUserMetadataListToShow(null);
                              } else {
                                setUserMetadataListToShow(room.joinedUsers);
                              }
                            }}
                          >
                            <div style={{ marginRight: 2 }}>
                              <p
                                className="font-sans"
                                style={{ color: "white", fontSize: 12 }}
                              >
                                {room.joinedUsers.length}
                              </p>
                            </div>
                            <User
                              color="white"
                              size={15}
                              style={{ marginRight: 2 }}
                            />
                            <div>
                              <p
                                className="font-sans"
                                style={{ color: "white", fontSize: 12 }}
                              >
                                going
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CachedBackgroundImageDiv>
                  <div
                    className="flex flex-row justify-between items-center"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Button
                      className="text-white font-sans"
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                      variant="solid"
                      size="sm"
                      onClick={async () => {
                        if (!isLoggedIn && settled) {
                          navigate("/login");
                        } else {
                          if (room.roomStatus === UserRoomStatus.Joined) {
                            await leaveRoomStateUpdate(room.roomID);
                          } else {
                            await joinRoomStateUpdate(room.roomID);
                          }
                        }
                      }}
                    >
                      {`${
                        !isLoggedIn && settled
                          ? "Login to RSVP"
                          : room.roomStatus === UserRoomStatus.Joined
                          ? "Going!"
                          : "RSVP"
                      }`}
                    </Button>
                    <div>
                      <a
                        href={getGoogleCalendarLink({
                          eventTitle: room.name,
                          roomID: room.roomID,
                          startTime: new Date(room.scheduledStartTime),
                        })}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          className="text-white"
                          style={{ backgroundColor: "white", color: "black" }}
                          variant="solid"
                          size="sm"
                          isIconOnly
                          onClick={async () => {
                            await clickedCalendarButton({
                              variables: {
                                roomID: room.roomID,
                              },
                            });
                          }}
                        >
                          <CalendarAdd />
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}

      {/* Public rooms section */}
      <div className="flex flex-col items-start" style={{ marginTop: 50 }}>
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Shared rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
          {loading ? (
            <div style={{ height: 200 }} />
          ) : (
            sharedRooms.map((room: RoomMetadata) => (
              <CachedBackgroundImageDiv
                clickable={true}
                imageURL={room.imageDownloadURL || EarthBackground}
                key={room.roomID}
                roomID={room.roomID}
                draftID={
                  mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                    .draftID
                }
              >
                <div
                  className="flex flex-col blurred-div"
                  style={{
                    margin: 10,
                    borderRadius: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                  }}
                >
                  <p
                    className="font-sans text-white"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginBottom: 4,
                    }}
                  >
                    {room.name}
                  </p>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row items-center">
                      {room.private && (
                        <div style={{ marginRight: 5 }}>
                          <Lock color="white" variant="Bold" size={15} />
                        </div>
                      )}
                      {room.endTime && new Date(room.endTime) > currentTime ? (
                        <div className="flex flex-row items-center">
                          <div style={{ marginRight: 5 }}>
                            <Timer1 color="white" size={15} />
                          </div>
                          <p
                            className="font-sans"
                            style={{ fontSize: 12, color: "white" }}
                          >
                            {formatTimeDifference(
                              new Date(room.endTime),
                              currentTime
                            )}
                          </p>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className="flex flex-row items-center"
                        style={{ marginRight: 6 }}
                        onMouseEnter={() => setHoveredRoomID(room.roomID)}
                        onMouseLeave={() => setHoveredRoomID(null)}
                      >
                        {room.friendsInRoom.map((friend) => (
                          <motion.div
                            animate={{
                              marginLeft:
                                hoveredRoomID === room.roomID ? 2 : -8,
                            }}
                            transition={{
                              duration: 0.1,
                            }}
                            style={{
                              position: "relative",
                            }}
                            key={friend.userID}
                          >
                            <ProfileAvatar
                              src={friend.profilePhotoURL || ""}
                              profilePlaceholder={friend.profilePlaceholder}
                              style={{
                                width: 18,
                                height: 18,
                              }}
                              onMouseEnter={() =>
                                setHoveredFriendID(friend.userID)
                              }
                              onMouseLeave={() => setHoveredFriendID(null)}
                            />
                            {hoveredRoomID === room.roomID &&
                              hoveredFriendID === friend.userID && (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: -40,
                                    left: -10,
                                    backgroundColor: "black",
                                    padding: 5,
                                    margin: 5,
                                  }}
                                >
                                  <p
                                    className="font-sans"
                                    style={{ color: "white", fontSize: 12 }}
                                  >
                                    {friend.username}
                                  </p>
                                </div>
                              )}
                          </motion.div>
                        ))}
                      </div>
                      <div style={{ marginRight: 2 }}>
                        <p
                          className="font-sans"
                          style={{ color: "white", fontSize: 12 }}
                        >
                          {room.numUsersInRoom}
                        </p>
                      </div>
                      <User color="white" size={15} />
                    </div>
                  </div>
                </div>
              </CachedBackgroundImageDiv>
            ))
          )}
        </div>
      </div>

      {/* Owned rooms section */}
      <div className="flex flex-col items-start" style={{ marginTop: 50 }}>
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Your rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
          {loading
            ? null
            : ownedRooms.map((room: RoomMetadata) => (
                <CachedBackgroundImageDiv
                  clickable={true}
                  key={room.roomID}
                  imageURL={room.imageDownloadURL || EarthBackground}
                  roomID={room.roomID}
                  draftID={
                    mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                      .draftID
                  }
                >
                  {/* Room details */}
                  <div
                    className="flex flex-col blurred-div"
                    style={{
                      margin: 10,
                      borderRadius: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      width: 180,
                    }}
                  >
                    <p
                      className="font-sans text-white"
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        marginBottom: 4,
                      }}
                    >
                      {room.name}
                    </p>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        {room.private && (
                          <div style={{ marginRight: 5 }}>
                            <Lock color="white" size={15} variant="Bold" />
                          </div>
                        )}
                        {room.endTime &&
                        new Date(room.endTime) > currentTime ? (
                          <div className="flex flex-row items-center">
                            <div style={{ marginRight: 5 }}>
                              <Timer1 color="white" size={15} />
                            </div>
                            <p
                              className="font-sans"
                              style={{ fontSize: 12, color: "white" }}
                            >
                              {formatTimeDifference(
                                new Date(room.endTime),
                                currentTime
                              )}
                            </p>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="flex flex-row items-center">
                        <div style={{ marginRight: 2 }}>
                          <p
                            className="font-sans"
                            style={{ color: "white", fontSize: 12 }}
                          >
                            {room.numUsersInRoom}
                          </p>
                        </div>
                        <User color="white" size={15} />
                      </div>
                    </div>
                  </div>
                </CachedBackgroundImageDiv>
              ))}

          {/* Create new room button */}
          <div
            onMouseEnter={() => setCreateNewHovered(true)}
            onMouseLeave={() => setCreateNewHovered(false)}
            className="flex flex-col items-center justify-center"
            style={{
              width: 200,
              height: 200,
              backgroundColor: createNewHovered ? "#7194A8" : "#d2d2d2",
              borderRadius: 6,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/create-room");
            }}
          >
            <AddSquare color={"white"} size={80} style={{ strokeWidth: 0.5 }} />
            <p
              className="font-sans"
              style={{
                color: "white",
                fontSize: 16,
              }}
            >
              Create new room
            </p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={userMetadataListToShow !== null}
        onClose={() => setUserMetadataListToShow(null)}
        className="max-h-[90vh] overflow-y-auto"
      >
        <ModalContent className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col w-full" style={{ padding: 20 }}>
            <div
              className="flex flex-row justify-between w-full"
              style={{ marginBottom: 20 }}
            >
              <p className="font-sans font-semibold" style={{ fontSize: 20 }}>
                RSVP list
              </p>
            </div>
            <div className="flex flex-col w-full">
              {userMetadataListToShow
                ? userMetadataListToShow.map((f) => (
                    <div
                      className="flex flex-row w-full cursor-pointer items-center"
                      onClick={() => {
                        setUserMetadataListToShow(null);
                        navigate(`/writer/${f.username}`);
                      }}
                      key={f.username}
                      onMouseEnter={() => setHoveringFriendUsername(f.username)}
                      onMouseLeave={() => setHoveringFriendUsername(null)}
                      style={{
                        backgroundColor:
                          hoveringFriendUsername === f.username
                            ? "#f0f0f0"
                            : "transparent",
                        padding: 10,
                        borderRadius: 6,
                      }}
                    >
                      <ProfileAvatar
                        src={f.profilePhotoURL ?? ""}
                        profilePlaceholder={f.profilePlaceholder}
                        showFallback
                        style={{ width: 30, height: 30, marginRight: 10 }}
                      />
                      <p>{f.username}</p>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}
