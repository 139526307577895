import { gql, useQuery } from "@apollo/client";
import { ActivityLog } from "../gql/graphql";
import { ActivityLogFeedItem } from "./ActivityLogFeedItem";

const GET_ACTIVITY_LOG_FEED = gql`
  query GetActivityLogFeed {
    getActivityLogFeed(request: {}) {
      activityLogID
      templatedLog
      createdAt
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query ActivityLogGetMostRecentlyEditedDraft {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

export default function ActivityLogFeed() {
  const { data, loading } = useQuery(GET_ACTIVITY_LOG_FEED, {
    fetchPolicy: "no-cache",
  });
  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );
  if (loading) {
    return null;
  }
  return (
    <div
      style={{
        marginTop: 20,
        marginLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        minWidth: 300,
        maxHeight: "calc(100dvh - 77px)",
        overflowY: "auto",
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <p className="text-xl font-sans" style={{ fontWeight: "bold" }}>
          Recent activity
        </p>
      </div>
      {data.getActivityLogFeed.map((activityLog: ActivityLog) => (
        <ActivityLogFeedItem
          key={activityLog.activityLogID}
          activityLog={activityLog}
          draftID={
            mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft?.draftID
          }
        />
      ))}
      {data.getActivityLogFeed.length === 0 && (
        <div className="font-sans" style={{ fontSize: 14, marginBottom: 8 }}>
          Add friends to see their activity!
        </div>
      )}
    </div>
  );
}
