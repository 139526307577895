export function convertSecondsToString(
  seconds: number,
  options: { showSeconds?: boolean } = { showSeconds: true }
) {
  // convert to hours:minutes:seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // if hours is 0, don't display it
  const hoursString = hours ? `${hours}` : "";
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsString =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  if (!options.showSeconds) return `${hoursString}:${minutesString}`;
  return `${hoursString}${hoursString && ":"}${minutesString}:${secondsString}`;
}

export function formatTimeDifference(endTime: Date, startTime: Date) {
  const timeDifference = endTime.getTime() - startTime.getTime();
  return convertSecondsToString(timeDifference / 1000);
}

export function parseHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
}

export function formatNumber(num: number) {
  if (num < 1000) return num.toString();
  if (num < 10000) return `${(Math.trunc(num / 10) / 100).toFixed(2)}k`;
  if (num < 100000) return `${(Math.trunc(num / 100) / 10).toFixed(1)}k`;
  if (num < 1000000) return `${Math.trunc(num / 1000).toFixed(0)}k`;
  if (num < 10_000_000)
    return `${(Math.trunc(num / 10_000) / 100).toFixed(2)}m`;
  if (num < 100_000_000)
    return `${(Math.trunc(num / 100_000) / 10).toFixed(1)}m`;
  if (num < 1000000000) return `${Math.trunc(num / 1000_000).toFixed(0)}m`;
  return `${(num / 1000000000).toFixed(2)}b`;
}

export function getCounterColor(num: number) {
  if (num < 1_000) return "black";
  if (num < 1_000_000) return "#25536D";
  return "#E5C852";
}

function formatGoogleCalendarTime(date: Date) {
  return (
    date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    date.getDate().toString().padStart(2, "0") +
    "T" +
    date.getHours().toString().padStart(2, "0") +
    date.getMinutes().toString().padStart(2, "0") +
    date.getSeconds().toString().padStart(2, "0")
  );
}

// calendar link format: https://calendar.google.com/calendar/u/0/r/eventedit?text=Draft%20Zero+Writing+Session&details=Come+for+a+fun+writing+session+at+draftzero.com!&dates=20241116T140000/20241116T150000
export function getGoogleCalendarLink({
  eventTitle,
  roomID,
  startTime,
}: {
  eventTitle: string;
  roomID: string;
  startTime: Date;
}) {
  const urlEncodedTitle = encodeURIComponent(eventTitle);
  const oneHourLater = new Date(startTime.getTime() + 60 * 60 * 1000);
  return `https://calendar.google.com/calendar/u/0/r/eventedit?text=Draft+Zero+Event:+${urlEncodedTitle}&location=https://draftzero.com/room/${roomID}&details=Writing+room+link:+https://draftzero.com/room/${roomID}&dates=${formatGoogleCalendarTime(
    startTime
  )}/${formatGoogleCalendarTime(oneHourLater)}`;
}
